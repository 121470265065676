import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "オンライン診療アプリ「CLINICS」の開発で重視している 3 つの習慣",
  "date": "2018-01-10T03:05:00.000Z",
  "slug": "entry/2018/01/10/120500",
  "tags": ["medley"],
  "hero": "./2018_01_10.png",
  "heroAlt": "習慣"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の横尾です。`}</p>
    <p>{`これまで介護施設の口コミサイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」の立ち上げや医療介護の人材採用システム「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」のディレクター・コンテンツ編集などを経験し、現在はオンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS（クリニクス）`}</a>{`」のディレクターをしています。`}</p>
    <p>{`CLINICS では、慎重さとスピード感を両立して開発できるよう、3 つの習慣を取り入れています。半年ほど実施してみて手法としてまとまってきましたので、ブログでも紹介させていただきます。`}</p>
    <h1>{`背景`}</h1>
    <p>{`CLINICS は、スマートフォンや PC からビデオチャットでかかりつけ医の診察を受けられるオンライン診療アプリです。医療機関向けにはブラウザで予約管理や問診、診察、会計を行うことができる機能も提供しています。CLINICS を開発する際は、医療に関するプロダクトゆえに特に意識しなければいけない点がいくつかあります。`}</p>
    <p>{`一つは人の命に関わるということ。オンライン診療は症状の落ち着いている方が対象となりますが、少しの使いにくさやミスも誰かの生命・生活に何らか影響するかもしれない緊張感が常にあります。`}</p>
    <p>{`もう一つは、老若男女幅広く使われていること。病院にかかったことのない人はほとんどいないように、さまざまな人が CLINICS を利用しているので、どんな人でも不安なくスムーズに使えることが重要になってきます。`}</p>
    <p>{`このような背景から、CLINICS では`}<strong parentName="p">{`慎重にゴールを設定し、丁寧に検証しながら開発を進める必要があります`}</strong>{`。一方で、あまりに`}<strong parentName="p">{`慎重・丁寧すぎても何もできなかったり迷走したりしてスピード感を失いかねません`}</strong>{`。そこで、両者のバランスをうまく取り開発を推進するために次の 3 つを行っています。`}</p>
    <h1>{`CLINICS 開発をうまく進める 3 つの習慣`}</h1>
    <h2>{`1. プロダクトプリンシプルを共有する`}</h2>
    <p>{`プロダクトプリンシプルは、`}<strong parentName="p">{`サービスとしてのやること・やらないことを簡潔に言語化`}</strong>{`したものです。`}</p>
    <p>{`例えば CLINICS のプロダクトプリンシプルには「1UU の重み、活用率 100%を目指すこだわり：一人の人の命を預かっているという使命感を持ち、一人でも多くの人が使え、今まで使えていた人が使えなくなることが一人でもないようにする」というフレーズがあります。`}</p>
    <p>{`前述の通り、CLINICS は人の命に関わるプロダクトであり老若男女幅広く使われています。そのためあえてターゲットを絞らず、すべての人が使えるプロダクトを目指していることをこのフレーズで共有しています。これにより、施策検討段階では一部の人にとってとても使いやすいが使いこなせない人がいる UI と、ほぼすべての人がそこそこ使える UI があった時、私たちは迷わず後者を選択でき、すぐに実装に取り掛かることができます。`}</p>
    <p>{`プロダクトプリンシプルの作成にあたっては、日頃寄せられる患者の声・医療機関の声をよく聞き、医療の成り立ちや医療業界の構造なども深いところまで学習するようにしました。患者にとって、医療機関にとって、そして社会にとってのあるべきプロダクト像を理念として映し出すようにしています。`}</p>
    <h2>{`2. 年間・四半期ごとのロードマップを策定する`}</h2>
    <p>{`ロードマップは、`}<strong parentName="p">{`いつ頃どんな課題に取り組むのかを概ね年間・四半期ごとに定めたもの`}</strong>{`です。こちらは比較的多くの開発現場で取り入れられている方法ではないでしょうか。`}</p>
    <p>{`ロードマップをチームで意識するようにすると、先々に開発・リリースする機能を一人ひとりが理解でき、先回りして技術調査を進めたり関連する施策の効果分析の優先度を上げたりといったことができます。また、営業チームなど社内のほかの部署から開発提案があった際にはロードマップを見ながら今後の見通しを案内できるようになり、 他チームのメンバーからの信頼を得ながら開発に専念することができます。`}</p>
    <p>{`ロードマップを策定する際は、プロダクトプリンシプルを開発内容として落とし込みつつ、事業として伸ばすべき数値や直近で解決すべきお問い合わせなども考慮するようにしています。また、四半期ごとに KPT 法を使ってロードマップを振り返り、開発の期間や体制を見直すようにしています。`}</p>
    <h2>{`3. 週次で計画し、日次で進捗を追う`}</h2>
    <p>{`週次の計画と日次の進捗確認には、`}<strong parentName="p">{`スタンディングミーティング`}</strong>{`を行っています。`}</p>
    <p>{`CLINICS では GitHub のプロジェクト機能を使ってカンバンを作成しています。任意のタイミングでロードマップで挙げた課題をだいたい 1 週間程度で区切りのつくサイズの issue に落とし込み、カンバンの backlog にセットします。毎週月曜日の午前中に週次定例を行い、その週に着手する issue を backlog から in progress に移します。火曜以降は毎日 issue ごとに進捗を確認し、終わったものを done に移していきます。`}</p>
    <p>{`このように週次・日次で計画と進捗確認を細かく繰り返すことで、一つひとつの issue をスピーディに進めるリズムができます。ミーティング時点で一つの issue も進んでいないということがないようにしようという意識が働き、個々人のパフォーマンスもアップします。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回は、開発推進のために CLINICS で取り入れている習慣をご紹介しました。`}</p>
    <p>{`この 3 つの習慣は、`}<strong parentName="p">{`どれか 1 つのみを行うのでもだめで、3 つセットで実施することが今の CLINICS 開発のカギ`}</strong>{`になっています。プロダクトプリンシプルだけでは理想論のように思えたことも、ロードマップや計画になるとより具体的になって着手しやすくなり、また、計画で示しきれない改善の方向性はプロダクトプリンシプルに立ち返ることでお互いの認識をそろえやすくなります。`}</p>
    <p>{`そうすれば不必要なルールの制定や社内コミュニケーションから解放され、日々やるべきことに集中でき、`}<strong parentName="p">{`結果的により早くより優れたプロダクトをユーザーに届けることができる`}</strong>{`と考えています。`}</p>
    <p>{`メドレーでは、こうした様々な手法を取り入れながら新しい医療体験を提供するプロダクトを、一緒に創るディレクターやエンジニア、デザイナーを募集しています。`}</p>
    <p>{`ご興味ある方は、まずはお気軽に話を聞きにお越しください！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      